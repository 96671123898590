// Tab Style 01
.tab-style-01 {
  &.nav-tabs {
    .nav-item {
      @apply sm:w-full sm:flex sm:justify-center sm:m-0 sm:mt-0 sm:mx-auto sm:mb-[25px];
    }

    .nav-link {
      @apply font-serif border-0 relative text-tprimary text-nowrap border-none p-0 font-medium uppercase bg-transparent;
      transition: all 0.5s ease-in-out;


      &::after {
        @apply bg-tprimary;
        bottom: -5px;
        width: 0;
        height: 2px;

        position: absolute;
        content: "";
        left: 0;
        transition: all 0.5s ease-in-out;
      }

      &:hover::after,
      &.active::after {
        @apply w-full text-tprimary;
      }

      &:hover,
      &.active {
        @apply text-tprimary;
      }
    }

    li {
      @apply px-[35px] py-0 relative leading-5;
    }
  }

  div[class*="col"] {
    a {
      transition: var(--default-transition);

      &:hover {
        background: transparent;
        color: red;
        font-weight: 500;
      }
    }
  }
}
